module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-preact@6.21.0_gatsby@4.21.1_preact-render-to-string@5.2.2_preact@10.10.6_webpack@5.74.0/node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@5.21.0_gatsby@4.21.1_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@4.21.0_gatsby@4.21.1_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vuong Trung Hieu Portfolio","short_name":"HieuPortfolio","start_url":"/","background_color":"#272727","theme_color":"#f3ca2f","display":"standalone","icon":"src/images/favicon.png","icon_options":{"purpose":"any maskable"},"icons":[{"src":"/favicons/192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1656b36c86301ed1d0cc3191ab1e0f8c"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-omni-font-loader@2.0.2_gatsby@4.21.1_react-helmet@6.1.0/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"interval":300,"timeout":30000,"web":[{"name":"Poppins","file":"https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap"}]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@4.21.1_babel-eslint@10.1.0_react-dom@17.0.2_react@17.0.2_typescript@4.7.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
